<template>
  <div>
    <div v-if="searchable" class="row mb-3">
      <div class="col-lg-4 col-md-4 col-sm-12 col-12">
        <div class="input-group">
          <input type="text" class="form-control float-left border-right-0"
          v-on:keyup.enter="filteredListq()"
          v-model="searchKey" placeholder="Pesquisar">
          <span class="input-group-append" @click="filteredListq()">
              <div class="input-group-text bg-transparent"><i class="fa fa-search"></i></div>
          </span>
        </div>
      </div>
    </div>
    <table class="table table-hover border table-striped" v-if="listItem">
      <thead>
        <slot name="columns">
          <th v-for="(column, index) in columns" :key="index" :class="{ mhand: column.sortable, 'text-right': column.field == 'actions' }" @click="sortBy(column.sortable)">{{column.label}} <span v-if="column.sortable !== ''" class="ml-1"><i class="fas fa-sort" style="font-size: 0.7rem;"></i></span></th>
        </slot>
      </thead>
      <tbody>
        <tr v-if="listItem.length === 0">
          <td :colspan="columns.length" class="text-center font-weight-bold">
            <span>Nenhum registro a ser exibido</span>
          </td>
        </tr>
        <tr v-else v-for="(item, index) in listItem" @click="sendItem(item)" :key="index" :class="{ 'text-danger': (item.hasOwnProperty('ativo') && item.ativo == 0) ? true : false }">
          <slot name="row" :row="item">
            <td v-for="(column, index) in columns" :key="index" :class="{'text-right': column.field == 'actions'}">
              <slot name="column" :item="item" :column="column">
                {{itemValue(item, column.field)}}
              </slot>
            </td>
          </slot>
        </tr>
      </tbody>
      <tfoot>
        <td class="controls" :colspan="columns.length">
          <span class="float-right">
            <button class="btn btn-sm mr-1 float-left font-weight-bold" :disabled="!hasPage(-1)" @click="prevPage"><i class="fas fa-caret-left align-bottom mr-1"></i>Anterior</button> 
            <button class="btn btn-sm ml-1 font-weight-bold" :disabled="!hasPage(1)"  @click="nextPage">Próximo<i class="fas fa-caret-right align-bottom ml-1"></i></button>
          </span>
        </td>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: 'MyTable',
  data: function () {
    var sortOrders = {}
    this.columns.forEach(function (key) {
      sortOrders[key.sortable] = 1
    })
    return {
      page: 0,
      pageSize: 15,
      // dataSize: '',
      searchKey: '',
      sortKey: '',
      sortOrders: sortOrders,
      listItem: []
    }
  },
  watch: {
    data: {
      handler () {
        this.filteredListq()
      }
    }
  },
  computed: {
    dataSize () {
      return this.filteredData ? this.filteredData.length : 0
    },
    filteredList () {
      let items = this.filteredData
      let s = this.page * this.pageSize
      let e = (this.page + 1) * this.pageSize
      if (items.length <= s) {
        s = 0
        e = this.page
        this.pageNumber(0)
      }
      return items.slice(s, e)
    },
    filteredData: function () {
      var sortKey = this.sortKey
      var filterKey = this.searchKey && this.searchKey.toLowerCase()
      var order = this.sortOrders[sortKey] || 1
      var data = this.data
      if (filterKey) {
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
      }
      if (sortKey) {
        data = data.slice().sort(function (a, b) {
          a = a[sortKey]
          b = b[sortKey]
          return (a === b ? 0 : a > b ? 1 : -1) * order
        })
      }
      return data
    },
    pageSizeModel: {
      get () {
        return this.pageSize
      },
      set (v) {
        this.pageSize = v
        this.pageNumber(0)
      }
    }
  },
  created () {
    this.filteredListq()
  },
  methods: {
    filteredListq () {
      let items = this.filteredDataq()
      let s = this.page * this.pageSize
      let e = (this.page + 1) * this.pageSize
      if (items.length <= s) {
        s = 0
        e = this.pageSize
        this.pageNumber(0)
      }
      this.listItem = items.slice(s, e)      
    },
    filteredDataq: function () {
      var sortKey = this.sortKey
      var filterKey = this.searchKey && this.searchKey.toLowerCase()
      var order = this.sortOrders[sortKey] || 1
      var data = this.data
      if (filterKey && this.searchable) {
        filterKey = filterKey.replace(/[^a-zA-Z0-9]/g, '')
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
          })
        })
      }
      if (sortKey) {
        data = data.slice().sort(function (a, b) {
          a = a[sortKey]
          b = b[sortKey]
          return (a === b ? 0 : a > b ? 1 : -1) * order
        })
      }
      // console.log('data')
      // console.log(data)
      return data
    },
    pageNumber (page) {
      this.page = page
    },
    sortBy: function (key = '') {
      if (key !== '') {
        this.sortKey = key
        this.sortOrders[key] = this.sortOrders[key] * -1
        this.filteredListq()
      }
    },
    itemValue (item, column) {
      if (column !== 'actions' && column !== 'custom') {
        return item[column]
      } else {
        return 'CUSTOM'
      }
    },
    hasPage: function (dir) {
      if (dir === -1 && (this.page > 0)) return true
      if (dir === 1 && (((this.page + 1) * this.pageSize) < this.dataSize)) return true
      return false
    },
    prevPage: function () {
      if (this.hasPage(-1)) {
        this.page--
        this.filteredListq()
      }
    },
    nextPage: function () {
      if (this.hasPage(1)) {
        this.page++
        this.filteredListq()
      }
    },
    sendItem: function (item) {
      this.$emit('receiveItem', item)
    }
  },
  props: {
    columns: {
      type: Array,
      default: new Array
    },
    data: {
      type: Array,
      default: new Array
    },
    itemsPerPage: {
      type: Number,
      default: 25
    },
    searchable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.controls {
  font-weight: 700;
  font-size: 1rem;
}
table > thead, table > tfoot {
  background-color: #f3f4f7;
}

@media only screen and (max-width: 992px) {
  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
